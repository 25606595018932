<template>
    <client-only>
        <v-navigation-drawer expand-on-hover permanent>
            <v-list v-model:opened="open" open-strategy="multiple"
            >
                <v-list-group value="Management" v-if="user?.roles.includes('ADMIN')">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                                prepend-icon="mdi-tools"
                                title="Management"
                                v-bind="props"
                        ></v-list-item>
                    </template>
                    <v-list-item :to="{name: 'management-applications'}" prepend-icon="mdi-application-brackets-outline"
                                 title="Applications"/>
                    <v-list-item :to="{name: 'management-users'}" prepend-icon="mdi-account-circle" title="Users"/>

                </v-list-group>

                <v-list-group value="Applications">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                                prepend-icon="mdi-application-brackets-outline"
                                title="Applications"
                                v-bind="props"
                        ></v-list-item>
                    </template>
                    <v-list-item
                            v-for="(navItem, i) of navItems"
                            :key="i"
                            :prepend-icon="navItem.icon"
                            :title="navItem.title"
                            :to="navItem.to"
                    />
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
    </client-only>
</template>

<script setup lang="ts">
import type {Role} from "@/types/roles";
import type {RouteLocationRaw} from "vue-router";

const applicationManagementStore = useApplicationManagementStore();
const authStore = useAuthorizationStore();

type NavItem = {
    title: string;
    icon: string;
    to: RouteLocationRaw;
    authorizedRoles: Role[];
};

const applications = computed(() => {
    return applicationManagementStore.getAllApplications;
});

const user = computed(() => {
    return authStore.getTokenPayload?.userData;
});

const navItems = computed(() => {
    const appNavItems = applications.value.map((app) => {
        return {
            title: app.name,
            icon: "mdi-xml",
            to: `/applications/${app.key}`,
            authorizedRoles: ["USER", `APP:${app.key}`],
        } as NavItem;
    });

    return appNavItems.filter((item) => {
        return item.authorizedRoles.some((role) => authStore.getUserRoles[role]);
    });
});

const open = ref(['Applications', 'Management'])
</script>

<style scoped lang="scss"></style>
